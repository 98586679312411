import { INaviationMenuProps } from './types'

export const mockItems: INaviationMenuProps['items'] = [
  {
    title: 'Lausnir',
    type: 'large',
    items: [
      {
        group: 'Einstaklingar',
        title: 'Auðkennisappið',
        description: 'Allt um auðkennisappið',
        icon: '#',
        url: 'https://visir.is',
      },
      {
        group: 'Einstaklingar',
        title: 'Rafræn skilríki á SIM',
        description: 'Allt um rafræn skilríki á SIM',
        icon: '#',
        url: 'https://visir.is',
      },
      {
        group: 'Einstaklingar',
        title: 'Skilríki á korti',
        description: 'Allt um skilríki á korti',
        icon: '#',
        url: 'https://visir.is',
      },
      {
        group: 'Fyrirtæki',
        title: 'Starfsskilríki',
        description: 'Allt um starfsskilríki',
        icon: '#',
        url: 'https://visir.is',
      },
      {
        group: 'Fyrirtæki',
        title: 'Búnaðarskilríki',
        description: 'Allt um búnaðarskilríki',
        icon: '#',
        url: 'https://visir.is',
      },
      {
        group: 'Fyrirtæki',
        title: 'Innsigli',
        description: 'Allt um innsigli',
        icon: '#',
        url: 'https://visir.is',
      },
      {
        group: 'Fyrirtæki',
        title: 'Þjónustuveitendur',
        description: 'Allt um þjónustuveitendur',
        icon: '#',
        url: 'https://visir.is',
      },
    ],
  },
  {
    title: 'Um Auðkenni',
    url: 'https://visir.is',
    type: 'link',
  },
  {
    title: 'Aðstoð',
    items: [
      {
        title: 'Spurningar og svör',
        url: 'https://visir.is',
      },
      {
        title: 'Skjalageymsla',
        url: 'https://visir.is',
      },
      {
        title: 'Tækniupplýsingar',
        url: 'https://visir.is',
      },
    ],
  },
]
