'use client'

import { motion } from 'framer-motion'
import { MouseEvent, PointerEvent, ReactNode } from 'react'

import { MenuItem } from './types'

import { Text } from '@/components/Text'
import { cn } from '@/lib/utils'

export const preventHover = (
  e: PointerEvent<HTMLElement> | MouseEvent<HTMLElement>,
) => {
  return e.preventDefault()
}

const desktopTransition = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 10,
  },
  transition: {
    duration: 0.15,
  },
  // use default mouseover for now...
  // onPointerLeave: preventHover,
  // onPointerMove: preventHover,
}

export const MegaMenuContent = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      {...desktopTransition}
      className={cn('px-6 py-7 rounded-8px bg-white ring-baby-blue ring-1')}
    >
      {children}
    </motion.div>
  )
}

export const MegaMenuCard = ({ title, description, icon }: MenuItem) => {
  return (
    <div
      className={cn(
        'ring-1 ring-baby-blue rounded-8px w-full text-left interactable max-w-[220px] relative',
      )}
    >
      <div className="flex gap-4 p-4 py-6">
        <div className="w-[20px] flex-shrink">{icon}</div>
        <div className="flex-grow space-y-2 overflow-hidden">
          <Text as="div" variant="button" className="truncate">
            {title}
          </Text>
          <Text as="div" variant="label-small" className="text-gray-4 truncate">
            {description}
          </Text>
        </div>
      </div>
    </div>
  )
}

export const DefaultContent = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      {...desktopTransition}
      className={cn(
        'p-6 rounded-8px bg-white ring-baby-blue ring-1 flex flex-col',
      )}
    >
      {children}
    </motion.div>
  )
}
