'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

import {
  ListItem,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from './components'
import { MegaMenuCard } from '../shared/components'
import { IMobileNaviationMenuProps, MenuItem } from '../shared/types'

import { Link } from '@/components/Link'
import { Text } from '@/components/Text'
import { itemsByGroup } from '@/lib/utils'

export const MobileMenu = ({
  onCloseDialog,
  items,
}: IMobileNaviationMenuProps) => {
  const [currentId, setCurrentId] = useState<string | undefined>(undefined)

  const onClose = () => {
    setCurrentId(undefined)
    onCloseDialog?.()
  }

  return (
    <>
      <NavigationMenu id="mobile-menu" key="MobileMenu" orientation="vertical">
        <NavigationMenuList className="flex flex-col">
          {(items ?? []).map(
            ({ type = 'default', title, url, icon, items }, index) => {
              const itemId = index.toString()
              let content = null

              switch (type) {
                case 'large':
                  content = (
                    <div className="px-8 py-6 flex flex-col w-full gap-6">
                      {Object.entries(itemsByGroup<MenuItem>(items ?? []))?.map(
                        ([group, items], index) => {
                          const listItems = items?.map((item, index) => {
                            return (
                              <ListItem
                                key={index}
                                onClose={onClose}
                                href={item?.url ?? ''}
                                className="max-w-[220px]"
                              >
                                <MegaMenuCard mobile {...item} />
                              </ListItem>
                            )
                          })

                          return (
                            <div key={index}>
                              {group !== 'DEFAULT' && (
                                <div className="mb-4 text-gray-4">
                                  <Text variant="menu">{group}</Text>
                                </div>
                              )}
                              <ul className="flex flex-col">{listItems}</ul>
                            </div>
                          )
                        },
                      )}
                    </div>
                  )
                  break
                case 'default':
                  content = (
                    <div className="pr-8 pl-12 py-6 flex flex-col w-full outline">
                      {items?.map(({ title, url }, index) => {
                        return (
                          <ListItem key={index} onClose={onClose} href={url}>
                            <Text variant="menu" as="span">
                              {title}
                            </Text>
                          </ListItem>
                        )
                      })}
                    </div>
                  )
                  break
                case 'link':
                  break
                default:
                  break
              }

              const isLink = type === 'link'

              return (
                <NavigationMenuItem key={index} value={index.toString()}>
                  {isLink && (
                    <NavigationMenuLink
                      onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                          onClose?.()
                        }
                      }}
                      onClick={() => {
                        onClose?.()
                      }}
                      href={url ?? '#'}
                      className={navigationMenuTriggerStyle()}
                      asChild
                    >
                      <Link href={url ?? '#'}>
                        <Text
                          variant="menu"
                          as="span"
                          className="flex items-center gap-2"
                        >
                          <span>{title}</span>
                          {icon ? <span>{icon}</span> : null}
                        </Text>
                      </Link>
                    </NavigationMenuLink>
                  )}
                  {!isLink && (
                    <>
                      <NavigationMenuTrigger
                        isOpen={currentId === itemId}
                        onClose={onClose}
                        onClick={() => {
                          setCurrentId(
                            currentId === itemId ? undefined : itemId,
                          )
                        }}
                        className="outline-none"
                      >
                        <Text variant="menu" as="span">
                          {title}
                        </Text>
                      </NavigationMenuTrigger>
                      <AnimatePresence>
                        {currentId === index.toString() && (
                          <NavigationMenuContent forceMount>
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: 'auto', opacity: 1 }}
                              exit={{
                                height: 0,
                                opacity: 0,
                              }}
                            >
                              {content}
                            </motion.div>
                          </NavigationMenuContent>
                        )}
                      </AnimatePresence>
                    </>
                  )}
                </NavigationMenuItem>
              )
            },
          )}
        </NavigationMenuList>
      </NavigationMenu>
    </>
  )
}

export default MobileMenu
