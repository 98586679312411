'use client'

import clsx from 'clsx'
import { motion } from 'framer-motion'
import { AlertTriangleIcon, XCircleIcon } from 'lucide-react'
import { useState } from 'react'

import { Link } from '../Link'
import { RichText as RichTextComponent } from '../RichText'
import { Text } from '../Text'

import { RichText } from '@/types/sanity.types'

export interface AlertBannerProps {
  id: string
  heading: string
  active?: boolean
  text: RichText | null
  closeLabel?: string
  className?: string
  type?: 'info' | 'warning'
  link?: {
    label: string
    href: string
  }
}

export const AlertBanner = ({
  id,
  heading,
  text,
  closeLabel,
  className,
  type = 'info',
  link,
}: AlertBannerProps) => {
  const [isDismissed, setIsDismissed] = useState(false)

  const alertId = `alert-${id}`

  const dismiss = () => {
    setIsDismissed(true)
  }

  return (
    <motion.div
      key={alertId}
      initial={{
        opacity: 1,
        height: 'auto',
        display: 'block',
      }}
      animate={{
        opacity: isDismissed ? 0 : 1,
        height: isDismissed ? 0 : 'auto',
        display: isDismissed ? 'hidden' : 'block',
        transition: isDismissed
          ? {
              opacity: {
                delay: 0,
              },
              height: {
                delay: 0.3,
              },
              display: {
                delay: 0.3,
              },
            }
          : {
              opacity: {
                delay: 0,
              },
              height: {
                delay: 0,
              },
              display: {
                delay: 0,
              },
            },
      }}
      transition={{
        duration: 0.3,
      }}
      role="alert"
      className={clsx('w-full overflow-hidden', className)}
    >
      <div
        className={clsx(
          'bg-baby-blue p-3 md:p-4 inline-flex w-full rounded-8px gap-3 md:gap-4 items-center mt-3 md:mt-4',
          {
            'bg-baby-blue': type === 'info',
            'bg-warning': type === 'warning',
          },
        )}
      >
        <div className="w-5 md:w-6 flex-shrink-0" aria-hidden="true">
          <AlertTriangleIcon width="100%" />
        </div>
        {heading && (
          <Text variant="label-consent" as="span" className="font-bold">
            {heading}
          </Text>
        )}
        <div className="truncate flex-grow text-label-consent-m md:text-label-consent">
          {text ? (
            <RichTextComponent
              richText={text}
              components={{
                block: {
                  normal: (node) => (
                    <Text variant="label-consent" as="span">
                      {node.children}
                    </Text>
                  ),
                  p: (node) => (
                    <Text variant="label-consent" as="span">
                      {node.children}
                    </Text>
                  ),
                },
                marks: {
                  innerPathLink: (node) => {
                    const { href } = node.value

                    return (
                      <Link
                        className={clsx('inline-block font-bold')}
                        href={href}
                      >
                        <span className="text-blue underline">
                          {node.children}
                        </span>
                      </Link>
                    )
                  },
                  internalLink: (node) => {
                    const slug = node.value?.slug
                    const href = slug ? `/${slug.current}` : '/'

                    return (
                      <Link
                        className={clsx('inline-block font-bold')}
                        href={href}
                      >
                        <span className="text-blue underline">
                          {node.children}
                        </span>
                      </Link>
                    )
                  },

                  externalLink: (node) => {
                    const { href } = node.value

                    return (
                      <Link
                        newTab
                        className={clsx('inline-block font-bold')}
                        href={href}
                      >
                        <span className="text-blue underline">
                          {node.children}
                        </span>
                      </Link>
                    )
                  },
                },
              }}
            />
          ) : null}
        </div>
        <div className="shrink-0 flex gap-4">
          {link && (
            <Link
              href={link.href}
              className="font-bold border-b border-dark text-dark"
            >
              {link.label}
            </Link>
          )}
          <button className="w-5 md:w-6 shrink-0" onClick={() => dismiss()}>
            {closeLabel && <span className="sr-only">{closeLabel}</span>}
            <XCircleIcon width="100%" />
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default AlertBanner
