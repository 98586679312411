'use client'

import React from 'react'
import { ReactNode } from 'react'

import { Text } from '@/components/Text'
import { useGlobalStore } from '@/hooks/useGlobalStore'
import { cn } from '@/lib/utils'

type CardType = {
  title: string
  text: string
  icon: ReactNode
  className?: string
  triggerWebChat?: boolean
  noHyphen?: boolean
} & (
  | { href: string; onClick?: never }
  | { href?: never; onClick: () => void }
  | { href?: never; onClick?: never }
)

export const Card = ({
  title,
  text,
  icon,
  className,
  triggerWebChat,
  noHyphen = false,
  ...props
}: CardType) => {
  const { webChat } = useGlobalStore((state) => state)

  const Cmp = props?.href ? 'a' : 'button'

  return (
    <Cmp
      className={cn(
        'bg-white rounded-8px p-6 flex w-full md:max-w-[300px] relative gap-4 interactable',
        className,
      )}
      {...props}
      {...(triggerWebChat && {
        onClick: () => {
          if (typeof window !== 'undefined') {
            webChat?.selfHostedApp?.webchatRef?.current?.toggleWebchat()
          }
        },
      })}
    >
      <div className="shrink-0">{icon}</div>
      <div className="flex flex-col gap-2 text-left">
        <div className="text-dark leading-tight text-pretty">
          <Text variant="label-small">{title}</Text>
        </div>
        <div className="leading-tight text-pretty">
          <Text
            className="text-gray-4"
            variant="label-small"
            as="span"
            noHyphen={noHyphen}
          >
            {text}
          </Text>
        </div>
      </div>
    </Cmp>
  )
}

export default Card
