'use client'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'

import { cn } from '@/lib/utils'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    open?: boolean
  }
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => {
  return (
    <AnimatePresence>
      {props.open && (
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          forceMount
          asChild
          {...props}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className={cn(
              'z-50 p-6 rounded-8px shadow-base bg-white outline-none',
              className,
            )}
          >
            {props.children}
          </motion.div>
        </PopoverPrimitive.Content>
      )}
    </AnimatePresence>
  )
})

PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverContent, PopoverTrigger }
