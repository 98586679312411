'use client'

import { useEffect } from 'react'

import { useGlobalStore } from '@/hooks/useGlobalStore'

export const WebChat = () => {
  const { setWebChat } = useGlobalStore((state) => state)

  useEffect(() => {
    if (typeof window === 'undefined' || window.TalkdeskChatSDK) {
      return
    }

    const divContainer = document.createElement('div')
    divContainer.id = 'tdWebchat'
    document.body.appendChild(divContainer)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'tdwebchatscript'
    script.src = 'https://talkdeskchatsdk.talkdeskapp.com/talkdeskchatsdk.js'
    script.async = true
    document.getElementsByTagName('head')[0].appendChild(script)

    script.onload = function () {
      const webchat = window?.TalkdeskChatSDK?.('tdWebchat', {
        touchpointId: '39d358e05b804e1d8ea9f56c9e5e5ce0',
        accountId: '',
        region: 'td-us-1',
      })

      if (webchat) {
        webchat.init({
          enableValidation: false,
          enableEmoji: true,
          enableUserInput: true,
          enableAttachments: true,
          styles: {
            chatThemeColor: '#324CDB',
            chatTitle: 'Auðkenni netspjall',
            chatTitleTextColor: '#FFFFFF',
            chatSubtitleTextColor: '#FFFFFF',
            chatCloseButtonColor: '#FFFFFF',
            chatMinimizeButtonColor: '#FFFFFF',
            welcomeMessageBackgroundColor: '#324CDB',
            welcomeMessageTitleColor: '#FFFFFF',
            welcomeMessageContentColor: '#FFFFFF',
            customButtonBackgroundColor: '#324CDB',
            customButtonBorderColor: '#000000',
            customButtonColor: '#FFFFFF',
            userTextColor: '#FFFFFF',
            userBackgroundColor: '#3877BB',
            botTextColor: '#000000',
            botBackgroundColor: '#F6F6F7',
            avatarForegroundColor: '#FFFFFF',
            avatarBackgroundColor: '#115485',
            chatConfirmButtonFontColor: '#000000',
            triggerButtonContainerBackgroundColor: '#324CDB',
            chatDialogBoxEndChat: 'Ertu viss um að þú viljir enda spjallið?',
            chatConfirmButtonEndChat: 'Já, enda spjall',
            chatCancelButtonEndChat: 'Ekki enda spjall',
            endedChatMessage: 'Þessu spjalli er lokið',
            startChatButtonBackgroundColor: '#324CDB',
            startChatButtonTextColor: '#FFFFFF',
            startChatButtonBorderColor: '#000000',
            startChatButtonLabel: 'Byrja nýtt spjall',
          },
        })

        setWebChat(webchat)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default WebChat
