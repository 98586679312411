'use client'

import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode } from 'react'

import { MobileMenu } from '../MobileMenu'
import { MenuProps } from '../shared/types'

import { Button } from '@/components/Button'
import { useGlobalStore } from '@/hooks/useGlobalStore'

export interface BurgerButtonProps {
  labelMobileMenu?: string
  ariaLabel?: string
  items?: MenuProps['items']
  addons?: ReactNode[]
}

export const BurgerButton = ({
  labelMobileMenu = 'Valmynd',
  ariaLabel = 'Opna valmynd',
  items = [],
  addons,
}: BurgerButtonProps) => {
  const { mobileMenuOpen, setMobileMenuOpen } = useGlobalStore((state) => state)

  const onClose = () => {
    setMobileMenuOpen(false)
  }

  return (
    <Dialog.Root
      open={mobileMenuOpen}
      onOpenChange={(state) => {
        setMobileMenuOpen(state)
      }}
      modal
    >
      <Dialog.Trigger asChild>
        <Button
          aria-label={ariaLabel ?? labelMobileMenu}
          icon="menu"
          size="small"
          variant="ghost"
          className="bg-white text-gray-4"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {labelMobileMenu}
        </Button>
      </Dialog.Trigger>
      <AnimatePresence>
        {mobileMenuOpen && (
          <>
            <Dialog.Overlay forceMount asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="bg-white/50 fixed top-0 left-0 right-0 bottom-0 overflow-y-auto outline-none"
              >
                <Dialog.Content
                  forceMount
                  className="mx-4 py-4 mb-6 mt-24 rounded-8px bg-white ring-1 ring-baby-blue outline-none"
                >
                  <MobileMenu
                    onCloseDialog={onClose}
                    items={items}
                    addons={addons}
                  />
                </Dialog.Content>
              </motion.div>
            </Dialog.Overlay>
          </>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

export default BurgerButton
