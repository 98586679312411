import { create } from 'zustand'

import { TalkdeskResult } from '@/types/general'

interface IGlobalStore {
  mobileMenuOpen: boolean
  setMobileMenuOpen: (mobileMenuOpen: boolean) => void
  sidebarMenuOpen: boolean
  setSidebarMenuOpen: (sidebarMenuOpen: boolean) => void
  webChat: TalkdeskResult | null
  setWebChat: (webChat: TalkdeskResult) => void
}

export const useGlobalStore = create<IGlobalStore>((set) => ({
  mobileMenuOpen: false,
  setMobileMenuOpen: (mobileMenuOpen) => set({ mobileMenuOpen }),
  sidebarMenuOpen: false,
  setSidebarMenuOpen: (sidebarMenuOpen) => set({ sidebarMenuOpen }),
  webChat: null,
  setWebChat: (webChat) => set({ webChat }),
}))
