'use client'

import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import { cva } from 'class-variance-authority'
import { ChevronDown } from 'lucide-react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { ExtraProps } from '../shared/types'

import { LinkTransition } from '@/components/LinkTransition'
import { cn } from '@/lib/utils'

const NavigationMenu = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Root>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={cn(
      'z-10 flex max-w-max flex-1 items-center justify-center',
      className,
    )}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Root>
))
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

const NavigationMenuList = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.List>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={cn(
      'group flex flex-1 list-none items-center justify-center space-x-1',
      className,
    )}
    {...props}
  />
))
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

const NavigationMenuItem = NavigationMenuPrimitive.Item

const navigationMenuTriggerStyle = cva(
  /*tw*/ 'group inline-flex h-[45px] w-max items-center justify-center px-4 rounded-8px',
)

const NavigationMenuTrigger = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> & {
    isOpen?: boolean
    faded?: boolean
  } & ExtraProps
>(
  (
    { className, children, isOpen, faded, onClose, onKeyDown, ...props },
    ref,
  ) => (
    <NavigationMenuPrimitive.Trigger
      ref={ref}
      onKeyDown={(event) => {
        onKeyDown?.(event)

        if (event.key === 'Escape') {
          onClose?.()
        }
      }}
      // onPointerMove={(event) => event.preventDefault()}
      // onPointerLeave={(event) => event.preventDefault()}
      className={cn(
        navigationMenuTriggerStyle(),
        'group transition-opacity duration-300',
        className,
        {
          'opacity-50': faded,
        },
      )}
      {...props}
    >
      {children}
      <ChevronDown
        className={cn('relative ml-2 h-4 w-4 transition duration-200', {
          'rotate-180': isOpen,
        })}
        width={24}
        aria-hidden="true"
      />
    </NavigationMenuPrimitive.Trigger>
  ),
)
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const NavigationMenuContent = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    // onPointerEnter={(event) => event.preventDefault()}
    // onPointerLeave={(event) => event.preventDefault()}
    className={cn(className)}
    {...props}
  />
))
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const NavigationMenuLink = NavigationMenuPrimitive.Link

const ListItem = forwardRef<
  ElementRef<'a'>,
  ComponentPropsWithoutRef<'a'> & ExtraProps
>(({ className, children, href, onClose, onClick, ...props }, ref) => {
  return (
    <div>
      <NavigationMenuLink asChild>
        <LinkTransition
          ref={ref}
          href={href ?? ''}
          className={cn('rounded-8px inline-block w-full', className)}
          onClick={(e) => {
            onClick?.(e)
            onClose?.()
          }}
          {...props}
        >
          {children}
        </LinkTransition>
      </NavigationMenuLink>
    </div>
  )
})
ListItem.displayName = 'ListItem'

export {
  ListItem,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
}
