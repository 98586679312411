'use client'

import { useState } from 'react'

import styles from './TailwindScreenDebug.module.css'

export const TailwindScreenDebug = () => {
  const [showScreenSizeBar, setShowScreenSizeBar] = useState(false)
  const [hasOutline, setHasOutline] = useState(false)

  return (
    <>
      <button
        onClick={() => {
          setShowScreenSizeBar(!showScreenSizeBar)
        }}
        className="fixed bottom-0 left-0 z-[9999] flex gap-6 bg-[#222] px-4 py-1 text-[#fff]"
      >
        <div className="sm:hidden">xs</div>
        <div className="hidden sm:block md:hidden">sm</div>
        <div className="hidden md:block lg:hidden">md</div>
        <div className="hidden lg:block xl:hidden">lg</div>
        <div className="hidden xl:block 2xl:hidden">xl</div>
        <div className="hidden 2xl:block">2xl</div>
      </button>
      {showScreenSizeBar && (
        <button
          className="fixed bottom-10 left-0 z-[9999] bg-[#222] px-4 py-1 text-[#fff]"
          onClick={() => {
            const newOutline = !hasOutline
            setHasOutline(newOutline)
            newOutline
              ? window.document.body.classList.add(styles.outlineAll)
              : window.document.body.classList.remove(styles.outlineAll)
          }}
        >
          Outline {hasOutline ? '✓' : '✕'}
        </button>
      )}
      {showScreenSizeBar && (
        <div className="pointer-events-none fixed bottom-0 left-0 right-0 z-[9999] h-6">
          <div className="absolute bottom-0 left-0 hidden h-2 w-full max-w-screen-sm border-r-2 border-r-[#222] pr-2 text-right sm:block">
            <div className="absolute right-0 bottom-full translate-x-1/2 transform rounded bg-[#222] px-2 py-1 text-[10px] text-[#fff]">
              sm {'>'}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 hidden h-2 w-full max-w-screen-md border-r-2 border-r-[#222] pr-2 text-right md:block">
            <div className="absolute right-0 bottom-full translate-x-1/2 transform rounded bg-[#222] px-2 py-1 text-[10px] text-[#fff]">
              md {'>'}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 hidden h-2 w-full max-w-screen-lg border-r-2 border-r-[#222] pr-2 text-right lg:block">
            <div className="absolute right-0 bottom-full translate-x-1/2 transform rounded bg-[#222] px-2 py-1 text-[10px] text-[#fff]">
              lg {'>'}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 hidden h-2 w-full max-w-screen-xl border-r-2 border-r-[#222] pr-2 text-right xl:block">
            <div className="absolute right-0 bottom-full translate-x-1/2 transform rounded bg-[#222] px-2 py-1 text-[10px] text-[#fff]">
              xl {'>'}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 hidden h-2 w-full max-w-screen-2xl border-r-2 border-r-[#222] pr-2 text-right 2xl:block">
            <div className="absolute right-0 bottom-full translate-x-1/2 transform rounded bg-[#222] px-2 py-1 text-[10px] text-[#fff]">
              2xl {'>'}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TailwindScreenDebug
