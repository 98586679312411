'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { ArrowUpRight, ExternalLinkIcon } from 'lucide-react'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import {
  ListItem,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from './components'
import {
  DefaultContent,
  MegaMenuCard,
  MegaMenuContent,
} from '../shared/components'
import { mockItems } from '../shared/mock'
import { INaviationMenuProps, MenuItem } from '../shared/types'

import { Link } from '@/components/Link'
import { isInternalLink } from '@/components/Link/Link'
import { Text } from '@/components/Text'
import { cn, itemsByGroup } from '@/lib/utils'

export const DesktopMenu = ({
  items = mockItems,
  dropdownPositioning = 'start',
}: INaviationMenuProps) => {
  const [currentId, setCurrentId] = useState<string | undefined>(undefined)
  const [currentSubId, setCurrentSubId] = useState<string | undefined>(
    undefined,
  )
  const listRef = useRef<HTMLUListElement>(null)
  const menuRef = useRef<HTMLElement>(null)
  useClickAway(menuRef, () => {
    setCurrentId(undefined)
  })
  const [menuWidth, setMenuWidth] = useState<number>(0)
  const [menuLeft, setMenuLeft] = useState<number>(0)
  const [diff, setDiff] = useState<number>(0)
  const centered = dropdownPositioning === 'centered'

  const onTrigger = (e: MouseEvent) => {
    const triggerWidth = e.currentTarget.getBoundingClientRect()?.width
    const triggerLeft = e.currentTarget.getBoundingClientRect()?.left
    const menuDiff = triggerLeft - menuLeft + triggerWidth / 2 - 200 / 2
    setDiff(menuDiff)
  }

  const onResize = useCallback(() => {
    if (centered) {
      if (listRef.current) {
        setMenuWidth(listRef.current.getBoundingClientRect()?.width ?? 0)
      }

      if (menuRef.current) {
        setMenuLeft(menuRef.current.getBoundingClientRect()?.left ?? 0)
      }
    }
  }, [centered])

  useEffect(() => {
    if (centered) {
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', () => {
          onResize()
        })
      }

      onResize()
    }

    return () => {
      centered &&
        window.removeEventListener('resize', () => {
          onResize()
        })
    }
  }, [centered, onResize])

  const onClose = () => {
    setCurrentId(undefined)
  }

  return (
    <NavigationMenu
      id="desktop-menu"
      key="DesktopMenu"
      ref={menuRef}
      orientation="horizontal"
      value={currentId}
      onValueChange={(value) => {
        setCurrentId(value.toString())
      }}
    >
      <NavigationMenuList
        ref={listRef}
        className="flex justify-between xl:gap-6 ml-4"
      >
        {(items ?? []).map(
          ({ type = 'default', title, url, items }, mainIndex) => {
            const leftPos = type === 'large' ? menuWidth / 2 - 900 / 2 : diff

            const itemId = mainIndex.toString()
            let content = null

            const groups = Object.entries(itemsByGroup<MenuItem>(items ?? []))

            switch (type) {
              case 'large':
                content = (
                  <MegaMenuContent>
                    <div className="flex flex-row justify-between w-full gap-14">
                      {groups?.map(([group, items], index) => {
                        const listItems = items?.map((item, index) => {
                          return (
                            <ListItem
                              key={index}
                              onClose={onClose}
                              href={item?.url ?? ''}
                              className="min-w-[220px]"
                            >
                              <MegaMenuCard {...item} />
                            </ListItem>
                          )
                        })

                        return (
                          <div key={index} className="">
                            {group !== 'DEFAULT' && (
                              <div className="mb-4 text-gray-4">
                                <Text variant="menu">{group ?? ' '}</Text>
                              </div>
                            )}
                            <ul className="flex flex-col gap-4">{listItems}</ul>
                          </div>
                        )
                      })}
                    </div>
                  </MegaMenuContent>
                )
                break
              case 'default':
                content = (
                  <DefaultContent>
                    {items?.map(({ title, url }, index) => {
                      const itemId = `${mainIndex}-${index}`
                      const variants = {
                        initial: { opacity: 0, x: -6, y: 6, scale: 0.8 },
                        animate: { opacity: 1, x: 10, y: -2, scale: 1 },
                      }

                      return (
                        <ListItem
                          key={itemId}
                          onClose={onClose}
                          href={url}
                          onMouseEnter={() => {
                            setCurrentSubId(itemId)
                          }}
                          onMouseLeave={() => {
                            setCurrentSubId(undefined)
                          }}
                          className={cn('transition-opacity duration-300', {
                            'opacity-50': currentSubId
                              ? currentSubId !== itemId
                              : false,
                          })}
                        >
                          <motion.span
                            initial="initial"
                            whileHover="animate"
                            className="inline-flex w-full hover:text-blue transition transititon-colors duration-300 py-2"
                          >
                            <span className="leading-none">
                              <Text variant="menu" as="span">
                                {title}
                              </Text>
                            </span>
                            <motion.span
                              variants={variants}
                              className="text-blue"
                            >
                              <ArrowUpRight width={20} />
                            </motion.span>
                          </motion.span>
                        </ListItem>
                      )
                    })}
                  </DefaultContent>
                )
                break
              case 'link':
                break
              default:
                break
            }

            const isLink = type === 'link'
            const isExternal = !isInternalLink(url ?? '')

            return (
              <NavigationMenuItem
                key={mainIndex}
                value={mainIndex.toString()}
                onMouseEnter={(e) => {
                  !isLink && onTrigger(e)
                }}
                onClick={(e) => {
                  !isLink && onTrigger(e)
                }}
              >
                {isLink && (
                  <NavigationMenuLink
                    onClick={() => {
                      onClose()
                    }}
                    asChild
                  >
                    <Link
                      onMouseMove={() => {
                        currentId !== itemId && setCurrentId(itemId)
                      }}
                      href={url ?? '#'}
                      title="Vefur Auðkennis"
                      className={cn(
                        navigationMenuTriggerStyle(),
                        'transition-opacity duration-300',
                        {
                          'opacity-50': currentId
                            ? currentId !== itemId
                            : false,
                        },
                      )}
                    >
                      <Text
                        variant="menu"
                        as="span"
                        className="flex items-center gap-2 text-dark"
                      >
                        <span>{title}</span>
                        {isExternal && (
                          <span>
                            <ExternalLinkIcon height="14" />
                          </span>
                        )}
                      </Text>
                    </Link>
                  </NavigationMenuLink>
                )}
                {!isLink && (
                  <>
                    <NavigationMenuTrigger
                      isOpen={currentId === itemId}
                      onClose={onClose}
                      faded={currentId ? currentId !== itemId : false}
                      className={cn('', { 'ml-2': mainIndex === 0 })}
                    >
                      <Text variant="menu" as="span">
                        {title}
                      </Text>
                    </NavigationMenuTrigger>
                    <AnimatePresence>
                      {currentId === mainIndex.toString() && (
                        <NavigationMenuContent
                          forceMount
                          className={cn('absolute pt-3', {
                            '-ml-4': mainIndex === 0,
                          })}
                          style={
                            dropdownPositioning === 'centered'
                              ? {
                                  left: leftPos,
                                }
                              : {}
                          }
                        >
                          {content}
                        </NavigationMenuContent>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </NavigationMenuItem>
            )
          },
        )}
      </NavigationMenuList>
    </NavigationMenu>
  )
}

export default DesktopMenu
