'use client'

import * as RactDialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'

import { Button } from '../Button'
import { Text } from '../Text'

export interface CookieBannerProps {
  heading?: string
  text?: string
  allowLabel?: string
  denyLabel?: string
  onDeny?: () => void
  onAllow?: () => void
  open?: boolean
}

export const CookieBanner = ({
  heading = 'Vefkökur eru notaðar á þessari vefsíðu',
  text = 'Við nýtum vefkökur til að bæta upplifun þína af vefnum.',
  allowLabel = 'Leyfa',
  denyLabel = 'Hafna',
  onAllow,
  onDeny,
  open,
}: CookieBannerProps) => {
  return (
    <RactDialog.Root open={open} modal={false}>
      <AnimatePresence>
        {open && (
          <RactDialog.Portal forceMount>
            <RactDialog.Content
              onFocusOutside={(e) => e.preventDefault()}
              forceMount
              asChild
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed left-0 right-0 bottom-0 items-center outline-none pointer-events-none flex justify-center outline"
              >
                <div className="rounded-2xl shadow-pill flex flex-col md:flex-row gap-6 w-auto md:w-full md:max-w-[760px] px-8 py-8 md:py-10 m-4 bg-white z-50 justify-between pointer-events-auto">
                  <div className="space-y-4">
                    <RactDialog.Title className="grow">
                      <Text
                        variant="title-consent"
                        className="text-trueBlue font-medium"
                      >
                        {heading}
                      </Text>
                    </RactDialog.Title>
                    <RactDialog.Description>
                      <Text variant="label-consent">{text}</Text>
                    </RactDialog.Description>
                  </div>
                  <div className="flex gap-4 items-center w-full md:w-auto justify-center">
                    <Button
                      variant="white-new"
                      onClick={() => {
                        onAllow?.()
                        // handleAccept(true)
                      }}
                    >
                      {allowLabel}
                    </Button>
                    <Button
                      variant="white-new"
                      onClick={() => {
                        onDeny?.()
                        // handleAccept(false)
                      }}
                    >
                      {denyLabel}
                    </Button>
                  </div>
                </div>
              </motion.div>
            </RactDialog.Content>
          </RactDialog.Portal>
        )}
      </AnimatePresence>
    </RactDialog.Root>
  )
}

export default CookieBanner
