'use client'

import classNames from 'classnames'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { useState } from 'react'

import { Text } from '@/components/Text'

export const DraftMode = () => {
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom')
  const handleClick = () => {
    fetch('/api/disable-draft', {
      method: 'GET',
    }).then((res) => res.ok && location.reload())
  }

  return (
    <div
      className={classNames(
        'pointer-events-none fixed left-0 z-50 flex w-full justify-center gap-2',
        {
          'bottom-3': position === 'bottom',
          'top-3': position === 'top',
        },
      )}
    >
      <Text
        as="div"
        variant="label-small-bold"
        className="pointer-events-auto inline-flex items-center rounded-sm bg-black p-1 text-white"
      >
        PREVIEW
      </Text>
      <button
        className="pointer-events-auto inline-flex items-center rounded-sm bg-blue p-1"
        onClick={handleClick}
      >
        <Text as="span" variant="label-small-bold" className="text-white">
          Click to disable
        </Text>
      </button>
      <button
        className="pointer-events-auto inline-flex items-center rounded-sm bg-black p-1"
        onClick={() => setPosition(position === 'top' ? 'bottom' : 'top')}
      >
        <Text as="span" variant="label-small-bold" className="text-white">
          {position === 'bottom' ? <ArrowUp /> : <ArrowDown />}
        </Text>
      </button>
    </div>
  )
}
