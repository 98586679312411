'use client'

import Image from 'next/image'
import { useState } from 'react'

import { Button } from '@/components/Button'
import { Link } from '@/components/Link'
import qrcodes from '@/components/Qrcode/Qrcode'
import { Text } from '@/components/Text'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

export interface QRCodeDialogProps {
  labelGetApp?: string
  ariaLabel?: string
}

export const QRCodeDialog = ({
  labelGetApp = 'Náðu í appið',
  ariaLabel = 'Náðu í appið',
}: QRCodeDialogProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <Button
          variant="yellow"
          size="large"
          icon="qr-code"
          aria-label={ariaLabel ?? labelGetApp}
          onClick={() => {
            setOpen(!open)
          }}
        >
          {labelGetApp}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        open={open}
        sideOffset={24}
        align="end"
        onInteractOutside={() => {
          setOpen(false)
        }}
      >
        <div className="flex w-full flex-wrap gap-4">
          {qrcodes.map((code, key) => {
            return (
              <Link
                href={code.url ?? ''}
                key={key}
                className="space-y-4 text-center"
              >
                <Text variant="label-medium">{code.name}</Text>
                {/* {code({ size: 180 })} */}
                <Image
                  height={180}
                  width={180}
                  src={code.img}
                  alt={code.name}
                />
              </Link>
            )
          })}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default QRCodeDialog
