const qrcodes = [
  {
    name: 'Android',
    img: '/google-qr-code.svg',
    url: 'https://play.google.com/store/apps/details?id=is.audkenni.app',
  },
  {
    name: 'iOS',
    img: '/apple-qr-code.svg',
    url: 'https://apps.apple.com/kh/app/auðkenni/id1530922294',
  },
]

export default qrcodes
