'use client'

import Script from 'next/script'
import { useEffect, useMemo, useState } from 'react'

import { CookieBanner } from '../CookieBanner'

const devUrl = 'https://dash.cookiehub.com/dev/6663c805.js'
const prodUrl = 'https://cdn.cookiehub.eu/c2/6663c805.js'

export const CookieHub = () => {
  const [open, setOpen] = useState<boolean | undefined>(undefined)
  const [cookieHubReady, setCookieHubReady] = useState<boolean>(false)

  const ready = useMemo(() => typeof window !== 'undefined', [])

  const cookiehub = useMemo(() => {
    if (ready && cookieHubReady) {
      return window.cookiehub
    }
  }, [ready, cookieHubReady])

  const isDev = useMemo(() => {
    if (typeof window === 'undefined') {
      return undefined
    }

    return (
      process.env.NODE_ENV === 'development' ||
      window.location.hostname !== 'app.audkenni.is'
    )
  }, [])

  useEffect(() => {
    if (!ready || !cookieHubReady || !cookiehub) return

    const cpm = {
      enabled: false,
      settings: {
        cookiePreferencesButton: false,
      },
      onAllow: () => {
        setOpen(false)
      },
      onDeny: () => {
        setOpen(false)
      },
    }

    cookiehub.load(cpm)

    const hasAnswered = cookiehub.hasAnswered()
    const hasInitialised = cookiehub.hasInitialised

    if (hasInitialised && !hasAnswered) {
      setOpen(true)
    }
  }, [cookieHubReady, cookiehub, ready])

  return (
    <>
      <CookieBanner
        open={open}
        onAllow={() => {
          cookiehub.allowAll()
        }}
        onDeny={() => {
          cookiehub.denyAll()
        }}
      />
      {typeof isDev !== 'undefined' && (
        <Script
          src={isDev ? devUrl : prodUrl}
          data-cookiebanner="disable"
          strategy="afterInteractive"
          data-disable-initial-dialog="true"
          onLoad={() => {
            setCookieHubReady(true)
          }}
        />
      )}
    </>
  )
}

export default CookieHub
